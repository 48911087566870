var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-transition default-animation relative flex w-full flex-col items-start bg-white px-4 py-4"},[_c('div',{staticClass:"flex h-full w-full flex-col"},[_c('div',{staticClass:"relative flex w-full items-stretch text-base font-medium"},[_c('div',{staticClass:"flex w-full flex-col md:flex-row"},[_c('div',{staticClass:"flex h-full flex-grow flex-col truncate"},[_c('div',{staticClass:"mb-1 flex w-full flex-grow items-start space-x-4 truncate"},[_c('div',{staticClass:"flex w-full flex-grow flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-none overflow-hidden rounded-full border-4",class:!_vm.cat.active
                    ? 'border-gray-400 opacity-50 grayscale'
                    : 'border-teal-400'},[(_vm.cat.image)?_c('img',{attrs:{"src":_vm.getImage(_vm.cat.image)}}):_c('div',{staticClass:"flex aspect-square h-[40px] items-center justify-center rounded-full bg-gray-100 text-gray-300"},[_c('font-awesome-icon',{staticClass:"flex group-hover:hidden",attrs:{"icon":['fal', 'camera']}})],1)]),_c('div',{staticClass:"flex-grow flex-col truncate"},[_c('div',{staticClass:"flex flex-grow items-center space-x-2 truncate"},[_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.cat.name))]),_c('div',{staticClass:"rounded px-2 py-0.5 text-sm font-normal",class:_vm.isActive
                        ? 'bg-teal-100 text-teal-400'
                        : 'bg-gray-200 text-gray-500'},[_vm._v(" "+_vm._s(_vm.isActive ? 'Active' : 'Inactive')+" ")])]),_c('div',{staticClass:"flex space-x-1 text-sm font-normal text-gray-600"},[_c('div',{staticClass:"font-normal"},[_vm._v(" "+_vm._s(_vm.cat.type === 'kitten' || _vm.cat.isKitten ? 'Kitten' : 'Adult')+" ")])])])])]),_c('div',{staticClass:"mt-4 flex items-center text-sm font-normal"},[_c('div',{staticClass:"flex flex-col space-y-2 text-gray-600"},[_c('div',{staticClass:"whitespace-normal break-words px-2"},[_vm._v(" "+_vm._s(_vm.productString)+" ")]),_c('div',{staticClass:"inline-flex cursor-pointer items-center space-x-2 px-2 text-sm font-normal text-gray-600 hover:underline",on:{"click":_vm.toggleInfo}},[_c('div',[_vm._v(_vm._s(_vm.showInfo ? 'Hide details' : 'Show details'))]),_c('font-awesome-icon',{staticClass:"text-xs",attrs:{"icon":['far', _vm.showInfo ? 'chevron-up' : 'chevron-down']}})],1)])])]),(_vm.hasActions)?_c('div',{staticClass:"mt-6 grid w-full flex-none grid-cols-2 border-t-2 text-center md:ml-4 md:mt-0 md:w-48 md:grid-cols-1 md:border-l-2 md:border-t-0"},[_c('div',{staticClass:"col-span-2 flex cursor-pointer items-center justify-center space-x-1 border-b-2 py-3 text-center text-sm font-normal text-gray-600 hover:underline md:col-span-1 md:border-b-0 md:pt-0",on:{"click":function($event){return _vm.showModal('', 'EditCat', {
                cat: _vm.cat
              })}}},[_vm._v(" Update details ")]),(_vm.canMakeInactive && _vm.isActive)?_c('div',{staticClass:"flex cursor-pointer items-center justify-center space-x-2 border-r-2 py-3 text-center text-sm font-normal text-gray-600 hover:underline md:border-r-0 md:border-t-2",on:{"click":function($event){return _vm.showModal('', 'MakeInactive', {
                cat: _vm.cat,
                makeActive: false
              })}}},[_c('font-awesome-icon',{staticClass:"text-xs",attrs:{"icon":['far', 'ban']}}),_c('div',[_vm._v("Make inactive")])],1):_vm._e(),(!_vm.isActive && _vm.subscription.active)?_c('div',{staticClass:"flex cursor-pointer items-center justify-center space-x-2 py-3 text-center text-sm font-normal text-gray-600 hover:underline md:border-t-2",on:{"click":function($event){return _vm.showModal('', 'MakeInactive', {
                cat: _vm.cat,
                makeActive: true
              })}}},[_c('font-awesome-icon',{staticClass:"text-base",attrs:{"icon":['fal', 'thumbs-up']}}),_c('div',[_vm._v("Reactivate")])],1):_vm._e(),(_vm.canRemove)?_c('div',{staticClass:"flex cursor-pointer items-center justify-center space-x-2 py-3 text-center text-sm font-normal text-red-400 hover:underline md:border-t-2 md:pb-0",on:{"click":function($event){return _vm.showModal('', 'RemoveCat', {
                cat: _vm.cat
              })}}},[_c('font-awesome-icon',{staticClass:"text-xs",attrs:{"icon":['far', 'trash-can-xmark']}}),_c('div',[_vm._v("Remove")])],1):_vm._e()]):_vm._e()])]),(_vm.showInfo)?_c('div',{staticClass:"subscription-info flex flex-col px-2"},_vm._l((_vm.groupProducts(_vm.cat.products)),function(value,key){return _c('div',{key:`cat-${_vm.position}-${key}`,staticClass:"mt-2 flex w-full flex-col border-b-2 pb-3"},[_c('div',{staticClass:"mb-2 mt-2 text-xs uppercase italic text-gray-500"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-grow"},[_vm._v(_vm._s(key))]),(_vm.getTypeCount(key) === 'dry')?_c('div',[_vm._v(" "+_vm._s(_vm._f("dryFormat")(_vm.productsCount[_vm.getTypeCount(key)]))+" ")]):_c('div',[_vm._v(_vm._s(_vm.productsCount[_vm.getTypeCount(key)]))])])]),_vm._l((value),function(product){return _c('div',{key:`cat-${_vm.position}-${product.sku}`,staticClass:"flex w-full justify-between"},[_c('div',[_vm._v(" "+_vm._s(product.name)+" "),(product.isKitten)?_c('span',{staticClass:"ml-1"},[_vm._v(" (Kitten)")]):_vm._e()]),_c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('span',{key:_vm.getQuantity(
                    product.increments,
                    product.quantity,
                    product.foodtype
                  ),staticClass:"ml-2 text-gray-600"},[_vm._v(" "+_vm._s(_vm.getQuantity( product.increments, product.quantity, product.foodtype ))+" ")])])],1)])})],2)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }